import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  root: {
    maxWidth: 600,
    margin: '0 auto'
  },
  text: {
    ...theme.typography.body1
  }
})

function linkify(inputText) {
  var replacedText, replacePattern1, replacePattern2, replacePattern3;

  //URLs starting with http://, https://, or ftp://
  replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
  replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

  //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
  replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

  //Change email addresses to mailto:: links.
  replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
  replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

  return replacedText;
}

const LegalDocument = (props) => {
  const { classes, string } = props

  const linesToParagraphs = (...nodes) => {
    return nodes
      .map(node => typeof node === 'string' ?
        node.split('\n').map((text, index) => {
          // let sansNewLine = text.replace(/\r?\n|\r/)

          if (text.includes('<h2>')) {
            const cleanText = text.replace(/<(.|\n)*?>/g, '')

            return (
              <Typography key={index} variant='h5' component='h2'>{cleanText}</Typography>
            )
          }
          return (
            <p className={classes.text} key={index} dangerouslySetInnerHTML={{ __html: linkify(text) }}></p>
          )
        }) : node)
      .reduce((nodes, node) => nodes.concat(node), []);
  }

  return (
    <section className={classes.root}>
      <div>
        {linesToParagraphs(string)}
      </div>
    </section>
  );
}

export default withStyles(styles)(LegalDocument)
